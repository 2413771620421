<template>
  <div style="display: contents">
    <fw-content-loader v-if="loading" />
    <template v-else>
      <BlockPageHeader
        :page="page"
        :users="users"
        :show-edit-button="showEditButton"
        class="mb-5"
        @open="$emit('open', $event)"
        @edit="$emit('edit', page)"
      />
      <PanelPagesContentPreviewPage
        v-if="provider"
        :key="version?.key"
        :collaboration-provider="provider"
        :page-number="0"
        :editable="false"
        :page="page"
        :version="version"
        :show-chapters="showChapters"
      />
      <div v-else-if="!publishedVersion" class="py-10 text-center text-sm text-gray-500">Página não publicada</div>
    </template>
  </div>
</template>

<script>
import { HocuspocusProvider } from '@hocuspocus/provider'
import PanelPagesContentPreviewPage from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesContentPreviewPage'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import BlockPageHeader from '@/fw-modules/fw-core-vue/pages/components/blocks/BlockPageHeader'

export default {
  components: {
    PanelPagesContentPreviewPage,
    BlockPageHeader
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    version: {
      type: Object
    },
    users: {
      type: Object,
      default: () => {}
    },
    showEditButton: {
      type: Boolean,
      default: true
    },
    showChapters: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: true,
      provider: null,
      permissions: {
        read: false,
        write: false
      }
    }
  },

  computed: {
    userToken() {
      return this.$store.state.session.token
    },
    publishedVersion() {
      return this.page?.versions?.find(v => v.state == 'published')
    },
    lang() {
      return this.$store.state.language ?? 'pt'
    }
  },

  watch: {
    userToken() {
      //prepare editor
      if (this.provider == null) {
        this.prepareProvider()
      }
    }
  },

  created() {
    if (this.version) {
      this.prepareProvider()
    }
  },

  methods: {
    prepareProvider() {
      if (!this.userToken || this.provider || !this.version) return
      console.log('prepareProvider', this.userToken, this.provider, this.version)
      this.permissions.write = false
      this.permissions.read = true
      // let self = this
      this.provider = new HocuspocusProvider({
        url: FwEnvConfig.apiUrlHocusPocus,
        name: `pages:${this.page.key}:${this.version.key}:0`,
        token: this.userToken,
        onAuthenticationFailed: () => {
          console.error('Authentication failed')
          this.permissions.write = false
          this.permissions.read = false
        },
        onSynced: () => {
          console.log('Document synced')
          this.loading = false
          this.$emit('document-loaded')
        }
      })
      console.log('Provider created for: ', `pages:${this.page.key}:${this.version.key}:0`)
      this.provider.on('status', event => {
        this.hpstatus = event.status
      })
    }
  }
}
</script>
