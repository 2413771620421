<template>
  <div>
    <div v-if="showTitle" class="flex">
      <h1
        class="text-3xl pt-2 flex-1"
        :class="{
          'font-medium text-gray-300': page?.title == null,
          'font-bold text-gray-800': page?.title != null
        }"
      >
        <v-clamp autoresize :max-lines="3">{{ page?.title ?? 'Sem título' }}</v-clamp>
      </h1>
      <fw-button v-if="showEditButton" type="xlight" icon="edit" @click.native="$emit('edit')">
        {{ $t('edit') }}
      </fw-button>
    </div>
    <div v-if="showTitle" class="flex gap-5 items-center pt-2.5 pb-10">
      <div class="flex gap-2 items-center">
        <fw-avatar class="mr-1.5 flex-shrink-0" size="xs" :user="users[page.user_key]" />
        <div class="font-medium text-gray-800">
          {{ users[page.user_key]?.name ?? 'Sem autor' }}
        </div>
      </div>
      <div class="text-xs text-gray-500">{{ $t('created_at') }} {{ page.created_date | formatDateTime }}</div>
    </div>
    <PanelPageSubpages
      v-if="page.subpages?.length"
      :page="page"
      :users="users"
      :show-title="showTitle"
      @open="$emit('open', $event)"
    >
    </PanelPageSubpages>
  </div>
</template>

<script>
import PanelPageSubpages from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPageSubpages'
export default {
  name: 'BlockPageHeader',
  components: {
    PanelPageSubpages
  },
  props: {
    page: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Object,
      default: () => {}
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showEditButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "no_title": "Sem título",
    "created_at": "Criada em",
    "edit": "Editar"
  },
  "en": {
    "no_title": "No title",
    "created_at": "Created at",
    "edit": "Edit"
  }
}
</i18n>
